<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">{{ i18n('admin.title') }}</h2>
    </div>
    <!-- BEGIN: HTML Table Filter -->
    <TableFilter
      :disabled="loading"
      :filter-fields="filterFields"
      @filter="doFilter"
      @reset="doResetFilter"
    >
    </TableFilter>
    <!-- END: HTML Table Filter -->
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div
        class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
      >
        <div class="flex mt-5 sm:mt-0">
          <button
            v-if="hasPermissionToCreate"
            class="btn bg-theme-31 text-white sm:w-auto pb-2 pt-2"
            @click="$router.push('/admins/new')"
          >
            <PlusIcon class="w-5 h-5 mr-2 ml-2" /> {{ i18n('common.create') }}
          </button>
        </div>
      </div>
      <loading-spinner
        type="controller"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div ref="table" class="relative">
        <div class="overflow-x-auto">
          <table
            class="table table-report sm:mt-2"
            id="adminTable"
            ref="tableRef"
          >
            <thead>
              <tr>
                <th class="whitespace-nowrap" id="">#</th>
                <th
                  v-for="col in columns"
                  :key="col.name"
                  :id="
                    col.name === 'action'
                      ? 'ignore-2'
                      : col.name === 'avatar'
                      ? 'ignore-3'
                      : ''
                  "
                  class="text-center whitespace-nowrap"
                >
                  {{ i18n(col.label) }}
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length" :key="isRTL">
              <tr v-for="(row, index) in rows" :key="index">
                <td class="w-40">
                  <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                    {{ rowIndex(index) }}
                  </div>
                </td>
                <td
                  class="text-center flex items-center justify-center"
                  id="ignore-5"
                >
                  <app-list-item-image
                    :value="row.avatar?.publicUrl"
                    type="user"
                  ></app-list-item-image>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'fullName') }}
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenter(row, 'email') }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="font-medium whitespace-nowrap"
                    style="direction: ltr !important"
                  >
                    {{ presenter(row, 'phoneNumber') }}
                  </div>
                </td>
                <td class="text-center max-w-[8rem]">
                  <div
                    class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
                    style="
                       {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }
                    "
                  >
                    <Tippy
                      tag="div"
                      class="flex items-center gap-1 cursor-pointer whitespace-pre tippy"
                      :class="{
                        'hover:underline ': row.roles.length > 1
                      }"
                      :content="
                        row.roles.join(`, \n
                      `)
                      "
                      @click="setInfoModal(row)"
                    >
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#info-modal-preview"
                        class="flex items-center p-2 transition duration-300 ease-in-out bg-white dark:bg-dark1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      >
                        {{ truncate(row.roles.join(',')) }}</a
                      >
                    </Tippy>
                  </div>
                </td>
                <td class="text-center">
                  <div class="font-medium whitespace-nowrap">
                    {{ presenterDate(row, 'createdAt') }}
                  </div>
                </td>
                <td class="w-40">
                  <div
                    class="flex items-center justify-center whitespace-nowrap"
                    :class="{
                      'text-theme-24': row.disabled,
                      'text-green-600 dark:text-theme-30': !row.disabled
                    }"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-2 ml-2" />
                    {{
                      !row.disabled
                        ? i18n('common.activated')
                        : i18n('common.disabled')
                    }}
                  </div>
                </td>
                <td class="table-report__action w-56" id="ignore-6">
                  <div
                    class="flex justify-center items-center gap-3 text-theme-24"
                  >
                    <router-link
                      v-if="hasPermissionToEdit"
                      class="text-green-600 dark:text-theme-30 flex items-center gap-2 whitespace-nowrap"
                      :to="`/admins/${row.id}/edit`"
                    >
                      <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                    </router-link>

                    <router-link
                      class="text-theme-10 dark:text-theme-30 flex items-center gap-2 whitespace-nowrap"
                      :to="`/admins/${row.id}`"
                    >
                      <EyeIcon class="w-4 h-4" /> {{ i18n('common.view') }}
                    </router-link>
                    <a
                      v-if="hasPermissionToDestroy"
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#delete-modal-preview"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      @click="selectedId = row.id"
                      ><Trash2Icon class="w-4 h-4 mr-1 ml-1" />{{
                        i18n('common.delete')
                      }}</a
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <app-empty-page
            v-if="!rows.length && !loading"
            :label="i18n('empty.admin')"
            label-class="text-lg font-medium"
            icon-size="4x"
            class="box w-full py-64"
          ></app-empty-page>
        </div>
        <Pagination
          v-if="rows.length"
          :page="currentPage"
          :pageSize="pagination.limit"
          :rowsNumber="pagination.total"
          :showSizeChanger="true"
          :disabled="!rows.length"
          :isFirstPage="isFirstPage"
          :isLastPage="isLastPage"
          position="center"
          @next-page="doNextPage"
          @prev-change="doPrevPage"
          @size-change="doChangePaginationPageSize"
        ></Pagination>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Delete Modal -->
    <info-modal :user="infoUser" :roles="infoRoles" @close="closeInfoModal" />
    <DeleteModal item="admin" @delete="doDestroyAdmin" />
    <verifyModal />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import DeleteModal from '@/components/modals/delete-modal.vue'
import { GenericModel } from '@/shared/model/generic-model'
import { helper as $h } from '@/utils/helper'
import print from 'print-js'
import { AdminPermissions } from './admin-permissions'
import InfoModal from '@/components/modals/info-modal.vue'

export default defineComponent({
  components: {
    DeleteModal,
    InfoModal
  },
  setup() {
    const tableRef = ref()
    const tabulator = ref()
    const selectedId = ref('')
    const selectAll = ref(false)
    const isOpen = ref(false)
    const infoUser = ref(null)
    const infoRoles = ref(null)

    const setInfoModal = ({ fullName, roles }) => {
      infoUser.value = fullName
      infoRoles.value = [...roles]
    }
    const closeInfoModal = () => {
      infoUser.value = null
      infoRoles.value = null
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    onMounted(() => {
      reInitOnResizeWindow()
    })
    return {
      tabulator,
      selectedId,
      selectAll,
      tableRef,
      isOpen,
      setInfoModal,
      closeInfoModal,
      infoUser,
      infoRoles
    }
  },
  data() {
    return {
      filterInput: [],
      columns: [
        {
          name: 'avatar',
          field: 'avatar',
          label: 'iam.fields.avatar',
          align: 'center'
        },
        {
          name: 'userName',
          field: 'userName',
          label: 'iam.fields.userName',
          align: 'center'
        },
        {
          name: 'email',
          field: 'email',
          label: 'iam.fields.email',
          align: 'center'
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: 'iam.fields.phoneNumber',
          align: 'center'
        },
        {
          name: 'role',
          field: 'role',
          label: 'iam.fields.role',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'iam.fields.joinDate',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'iam.fields.status',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'user/list/rows',
      currentPage: 'user/list/currentPage',
      isFirstPage: 'user/list/isFirstPage',
      isLastPage: 'user/list/isLastPage',
      pagination: 'user/list/pagination',
      listLoading: 'user/list/loading'
    }),
    loading() {
      return this.listLoading || this.destroyLoading
    },
    hasPermissionToCreate() {
      return new AdminPermissions(this.currentUser).create
    },
    hasPermissionToEdit() {
      return new AdminPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new AdminPermissions(this.currentUser).delete
    },
    hasPermissionToChangeStatus() {
      return new AdminPermissions(this.currentUser).changeStatus
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    },
    statusOptions() {
      return [
        { label: this.i18n('common.activated'), value: false },
        { label: this.i18n('common.disabled'), value: true }
      ]
    },
    filterFields() {
      return [
        {
          label: this.i18n('iam.filter.fullName'),
          value: 'fullName',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.email'),
          value: 'email',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.phoneNumber'),
          value: 'phoneNumber',
          type: 'text'
        },
        {
          label: this.i18n('iam.filter.accountType'),
          value: 'accountType',
          type: 'text'
        },
        {
          label: this.i18n('auction.fields.status'),
          value: 'disabled',
          type: 'list',
          options: this.statusOptions
        }
      ]
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.admins')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.admins')
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'user/list/doFetch',
      doFetchNextPage: 'user/list/doFetchNextPage',
      doFetchPreviousPage: 'user/list/doFetchPreviousPage',
      doMountTable: 'user/list/doMountTable',
      doChangeSort: 'user/list/doChangeSort',
      doChangePaginationCurrentPage: 'user/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'user/list/doChangePaginationPageSize',
      doToggleUserAccountStatus: 'user/view/doToggleUserAccountStatus',
      doDestroy: 'user/list/doDestroy',
      doEnableAll: 'user/list/doEnableAllSelected',
      doDisableAll: 'user/list/doDisableAllSelected'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        this.rows.forEach(element => {
          this.selected.push(element.id)
        })
      }
    },
    clearSelection() {
      this.selected = []
      this.selectAll = false
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterRole(row, fieldName) {
      return row[fieldName] && row[fieldName].length > 0
        ? row[fieldName].join(',')
        : 'ـــ'
    },
    async doFilter(filter, orderBy) {
      this.filterInput = []
      if (!filter) return
      this.filterInput = filter
      await this.doFetchFirstPage(orderBy)
    },
    async doResetFilter() {
      this.filterInput = []
      await this.doFetchFirstPage()
    },
    async doEnableAllSelected() {
      await this.doEnableAll(this.selected)
      this.clearSelection()
    },
    async doDisableAllSelected() {
      await this.doDisableAll(this.selected)
      this.clearSelection()
    },
    async doDestroyAdmin() {
      await this.doDestroy(this.selectedId)
    },
    async doFetchFirstPage(orderBy = 'createdAt') {
      await this.doChangePaginationCurrentPage(1)
      await this.doFetch({
        type: 'admin',
        orderBy,
        filter: this.filterInput,
        pagination: {
          limit: 10,
          sortBy: 'desc'
        }
      })
    },
    async doNextPage() {
      await this.doFetchNextPage()
    },
    async doPrevPage() {
      await this.doFetchPreviousPage()
    },
    onExportXlsx() {
      $h.exportToXlsx(this.tableRef, 'adminTable', 'admins')
    },
    onDownloadPdf() {
      $h.downloadPdf('#adminTable', 'admin', [1, 2, 8])
    },
    onPrint() {
      print({
        printable: 'adminTable',
        type: 'html',
        scanStyles: true,
        targetStyles: ['*'],
        maxWidth: 300,
        ignoreElements: [
          'ignore-1',
          'ignore-2',
          'ignore-3',
          'ignore-4',
          'ignore-5',
          'ignore-6'
        ]
      })
    },
    truncate(txt) {
      return txt.length > 11 ? String(txt).substring(0, 8) + '...' : txt
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
:deep(.tippy) {
  white-space: pre-line;
  width: min-intrinsic;
  width: -moz-min-content;
  width: min-content;
  display: table-caption;
  display: -ms-grid;
  -ms-grid-columns: min-content;
  line-height: normal;
}
</style>
