import PermissionsClass from '@/security/Permission-classes'
import Permissions from '@/security/permissions'

export class AdminPermissions extends PermissionsClass {
  constructor(currentUser) {
    super(currentUser, {
      read: Permissions.userRead,
      create: Permissions.userCreate,
      edit: Permissions.userEdit,
      delete: Permissions.userDestroy,
      changeStatus: Permissions.userChangeStatus
    })
  }
}
