import PermissionChecker from '@/security/permission-checker'

class PermissionsClass {
  constructor(currentUser, permissions) {
    this.permissionChecker = new PermissionChecker(currentUser)
    this.permissions = permissions
    this.generatePermissions()
  }

  generatePermissions() {
    for (const permission in this.permissions) {
      this[permission] = this.permissionChecker.match(
        this.permissions[permission]
      )
    }
  }
}

export default PermissionsClass
