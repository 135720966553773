<template>
  <div id="info-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-5">
          <div class="text-center">
            <h2
              class="text-xl lg:text-2xl text-black mb-7 flex items-center justify-center"
            >
              {{ i18n(`modal.roleTitle`, [user]) }}
            </h2>
            <div class="grid grid-cols-12 gap-5 mb-5">
              <div
                class="text-gray-700 col-span-6 ltr:text-left rtl:text-right"
                v-for="(role, i) of roles"
                :key="role"
              >
                {{ i + 1 + ' - ' + role }}
              </div>
            </div>
          </div>
          <div class="text-center flex justify-end">
            <button
              type="button"
              data-dismiss="modal"
              class="btn btn-outline-secondary w-24 ml-1 mr-1"
            >
              {{ i18n(`common.close`) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    roles: {
      type: Array,
      required: true
    },
    user: {
      type: String,
      required: true
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
